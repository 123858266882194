import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: '首页',
  EnName: 'Home',
  meta: {
    activeIndex: '1',
    showMap: true, // 有地图
    seo: 'defaultSeo',
    seoRequestUrl: '/api/Index/index',
    EnName: 'Home'

  },
  component: () => import('../views/mgEntrance.vue'),
  redirect: '/index',
  children: [{
    path: '/index',
    name: '首页',
    EnName: 'Home',
    meta: {
      activeIndex: '1',
      showMap: true, // 有地图
      seo: 'defaultSeo',
      seoRequestUrl: '/api/Index/index',
      EnName: 'Home'

    },
    hidden: true,
    component: () => import('../views/index.vue')
  },
  {
    path: '/aboutUs',
    name: '关于我们',
    EnName: 'About Us',
    meta: {
      activeIndex: '2',
      showMap: true, // 有地图
      seo: 'defaultSeo',
      seoRequestUrl: '/api/About/index',
      EnName: 'About Us'

    },
    hidden: true,
    component: () => import('../views/aboutUs/aboutUs_index.vue')
  },
  {
    path: '/usServive',
    name: '我们的服务',
    EnName: 'Our Service',
    meta: {
      activeIndex: '3',
      EnName: 'Our Service'

    },
    hidden: true,
    component: () => import('../views/usService/usService.vue'),
    redirect: '/chinaEurope_train',
    children: [{
      path: '/chinaEurope_train',
      name: '中欧班列',
      EnName: 'China-Europe Railway Express',
      meta: {
        activeIndex: '3-1',
        seo: 'serveSeo',
        seoId: 34, // 栏目id
        EnName: 'China-Europe Railway Express'
      },
      hidden: false,
      component: () => import('../views/usService/chinaEurope_train.vue')
    },
    {
      path: '/businessServices',
      name: '中欧班列境外服务商',
      EnName: 'Business Service',
      meta: {
        activeIndex: '3-2-5',
        seo: 'serveSeo',
        seoId: 40,
        EnName: 'Business Service'
      },
      hidden: false,
      component: () => import('../views/usService/chinaRussian_logistics/businessServices.vue')
    },
    {
      path: '/chinaRussian_logistics',
      name: '中俄运输', // 中俄物流
      EnName: 'China Russian Logistics',
      component: () => import('../views/usService/chinaRussian_logistics/chinaRussian_logistics.vue'),
      meta: {
        activeIndex: '3-3',
        EnName: 'China Russian Logistics'
      },
      hidden: false,
      redirect: '/chinaRussian_transport',
      children: [{
        path: '/chinaRussian_transport',
        name: '中俄运输',
        EnName: 'Sino-Russian Transportation',
        meta: {
          activeIndex: '3-2-1',
          seo: 'serveSeo',
          seoId: 36,
          EnName: 'Sino-Russian Transportation'

        },
        hidden: false,
        component: () => import('../views/usService/chinaRussian_logistics/chinaRussian_transport.vue')
      },
      {
        path: '/airServices',
        name: '空运服务',
        EnName: 'Air Freight Service',
        meta: {
          activeIndex: '3-3-2',
          seo: 'serveSeo',
          seoId: 37,
          EnName: 'Air Freight Service'
        },
        hidden: false,
        component: () => import('../views/usService/chinaRussian_logistics/airServices.vue')
      },
      // {
      //   path: '/produetsEQingServices',
      //   name: '清关服务',
      //   EnName: 'Customs Clearance Service',
      //   meta: {
      //     activeIndex: '3-3-3',
      //     seo: 'serveSeo',
      //     seoId: 38,
      //     EnName: 'Customs Clearance Service'

      //   },
      //   hidden: false,
      //   component: () => import('../views/usService/chinaRussian_logistics/produetsEQingServices.vue')
      // },
      {
        path: '/overseasTransportation',
        name: '境外承运',
        EnName: 'Overseas transportation',
        meta: {
          activeIndex: '3-3-3',
          seo: 'serveSeo',
          seoId: 43, // 栏目id
          EnName: 'Overseas transportation'
        },
        hidden: true,
        component: () => import('../views/usService/chinaRussian_logistics/overseasTransportation.vue')
      },
      // {
      //   path: '/international_trade',
      //   name: '国际贸易',
      //   EnName: 'International Trade',
      //   meta: {
      //     activeIndex: '3-2-4',
      //     seo: 'serveSeo',
      //     seoId: 39,
      //     EnName: 'International Trade'

      //   },
      //   hidden: false,
      //   component: () => import('../views/usService/chinaRussian_logistics/international_trade.vue')
      // },
      {
        path: '/international_car',
        name: '国际汽运',
        EnName: 'International Land Transport',
        meta: {
          activeIndex: '3-3-4',
          seo: 'serveSeo',
          seoId: 41,
          EnName: 'International Land Transport'

        },
        hidden: false,
        component: () => import('../views/usService/chinaRussian_logistics/international_car.vue')
      },
      {
        path: '/international_sea',
        name: '国际海运',
        EnName: 'International Shipping',
        meta: {
          activeIndex: '3-3-5',
          seo: 'serveSeo',
          seoId: 42,
          EnName: 'International Shipping'

        },
        hidden: false,
        component: () => import('../views/usService/chinaRussian_logistics/international_sea.vue')
      }

      ]
    },
    {
      path: '/chinaEurope_seaRail',
      name: '中欧海铁联运',
      EnName: 'China Europe Sea Rail',
      meta: {
        activeIndex: '3-4',
        seo: 'serveSeo',
        seoId: 33,
        EnName: 'China Europe Sea Rail'

      },
      hidden: false,
      component: () => import('../views/usService/chinaEurope_seaRail.vue')
    },
    {
      path: '/overseas_house',
      name: '海外仓',
      EnName: 'Overseas House',
      meta: {
        activeIndex: '3-5',
        seo: 'serveSeo',
        seoId: 32, // 栏目id
        EnName: 'Overseas House'

      },
      hidden: false,
      component: () => import('../views/usService/overseas_house.vue')
    },
    {
      path: '/freightTracking_index',
      name: 'MG物流云系统',
      EnName: 'Logistics Cloud Platform',
      meta: {
        activeIndex: '3-6',
        seo: 'defaultSeo',
        seoId: 35, // 栏目id
        EnName: 'Logistics Cloud Platform'

      },
      hidden: false,
      component: () => import('../views/freightTracking/freightTracking_index.vue')
    }
    ]
  },
  {
    path: '/international_trade',
    name: '国际贸易',
    EnName: 'International Trade',
    meta: {
      activeIndex: '4',
      seo: 'serveSeo',
      seoId: 39,
      EnName: 'International Trade'

    },
    hidden: true,
    component: () => import('../views/usService/chinaRussian_logistics/international_trade.vue')
  },
  // {
  //   path: '/freightTracking_index',
  //   name: 'MG物流云系统',
  //   EnName: 'Logistics Cloud Platform',
  //   // name: '货运追踪',
  //   meta: {
  //     activeIndex: '4',
  //     seo: 'defaultSeo',
  //     seoRequestUrl: '/api/Freight/index',
  //     EnName: 'Logistics Cloud Platform'

  //   },
  //   hidden: true,
  //   component: () => import('../views/freightTracking/freightTracking_index.vue')
  // },
  {
    path: '/contactUs_index',
    name: '联系我们',
    EnName: 'Contact Us',
    meta: {
      activeIndex: '5',
      showMap: true, // 有地图
      hideSelect: true, // 地图下拉菜单隐藏 别的没传默认是隐藏的
      seo: 'contactUsSeo',
      seoRequestUrl: '/api/Contactus/index',
      EnName: 'Contact Us'

    },
    hidden: true,
    component: () => import('../views/contactUs/contactUs_index.vue')
  },
  {
    path: '/news',
    name: '新闻中心',
    EnName: 'News',
    hidden: false,
    component: () => import('../views/news/new.vue'),
    redirect: '/new_index',
    meta: {
      name: '新闻中心',
      EnName: 'Press Center'
    },
    children: [{
      path: '/new_index',
      name: '新闻中心',
      EnName: 'Press Center',
      hidden: false,
      component: () => import('../views/news/new_index.vue'),
      meta: {
        name: '新闻中心',
        EnName: 'Press Center'
      }
    },
    {
      path: '/new_details',
      name: '新闻中心详情',
      EnName: 'new_details',
      hidden: false,
      meta: {
        name: '新闻中心详情',
        EnName: 'Press Center'
      },
      component: () => import('../views/news/new_details.vue')
    }
    ]

  },
  {
    path: '/ownJumpPage',
    name: '中转页面',
    EnName: 'ownJumpPage',
    hidden: false,
    meta: {
      footerIsShow: true // 底部是否展示
    },
    component: () => import('../views/ownJumpPage')
  }
  ]
}

]

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,   //默认展示的 解析出来是 /
  base: '/h5/',
  routes
})

export default router
